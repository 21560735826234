import { getOssSign } from "@api/common";
import Vue from "vue";
import uploadToOss from "@/utils/uploadToOss";

export default {
    async getPolicy() {
        return new Promise(async (resolve) => {
            try {
                let { data } = await getOssSign();
                resolve(data);
            } catch (err) {
                console.log("err", err);
                resolve(null);
            }
        });
    },

    uploadFile(file, type) {
        return new Promise(async (resolve, reject) => {
            try {
                if (!type)
                    type = file.name.substring(file.name.lastIndexOf(".") + 1);
                let formData = new FormData();
                formData.append("type", type);
                const ossData = await uploadToOss.getPolicy();
                let pathKey =
                    ossData.dir +
                    Vue.prototype.$md5(new Date().valueOf() + file.name);
                formData.append("OSSAccessKeyId", ossData.accessid);
                formData.append("signature", ossData.signature);
                formData.append("policy", ossData.policy);
                formData.append("key", pathKey);
                formData.append("file", file?.row || file);
                formData.append("success_action_status", 200);
                await Vue.prototype.$axios.post(
                    "https://move-build.oss-cn-hangzhou.aliyuncs.com",
                    formData
                );
                const path = "https://oss.yanxin7.cn/" + pathKey;
                resolve(path);
            } catch (err) {
                reject(err);
            }
        });
    },
};
