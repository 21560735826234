<template>
    <div class="left-box">
        <div class="left-box__top">
            <div class="settle-tag"
                :style="{ background: isSettleUser ? '#ECFBF0' : 'rgba(238, 30, 30, .1)', color: isSettleUser ? '#20B84E' : '#EE1E1E' }">
                {{ isSettleUser ? "已" : "未" }}认证
            </div>
            <el-image :src="userInfos.headPortrait" :preview-src-list="[userInfos.headPortrait]"
                class="head-image"></el-image>
            <div>{{ userInfos.userName }}</div>
        </div>
        <div class="left-box__bottom">
            <div v-for="(item, index) in navList" :key="index">
                <div style="position:relative">
                    <div style="position:absolute;left:0;width:2px;height:40px;background:#007EF2;z-index
                    :9;top:20px;" v-show="activePathKey === item.key"></div>
                    <el-button type="text" class="nav-item" :class="{ 'nav-item-select': activePathKey === item.key }"
                        @click="toNavPage(item)">
                        <el-badge is-dot :hidden="item.key !== 'message' || unreads === 0">
                            <span>{{ item.name }}</span>
                        </el-badge>
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
import { getMsgUnread } from "@/api/personalCenter"
export default {
    props: {
        activePathKey: {
            type: String,
            default: "personalCenter"
        }
    },
    data() {
        return {
            navList: [
                { name: "个人中心", key: "personalCenter", route: "/personalCenter/personalCenter" },
                { name: "消息通知", key: "message", route: "/personalCenter/msgNotice" },
                { name: "企业认证", key: "enterCompany", route: "/personalCenter/enterpriseList" },
                { name: "申请加盟", key: "applyJoin" }
            ],
            unreads: 0,
        }
    },
    computed: {
        ...mapState("account", ["userInfos"]),
        isSettleUser() {
            return this.userInfos?.realName
        },
    },
    mounted() {
        this.getMsgUnread()
    },
    methods: {
        toNavPage(item) {
            if (item.key === this.activePathKey) return
            if (item.key === "applyJoin") {
                let vRouter = this.$router.resolve({
                    path: "/join/join",
                    params: {},
                });
                window.open(vRouter.href, "_blank");
            }
            this.$router.replace(item.route)
        },
        async getMsgUnread() {
            try {
                let { data } = await getMsgUnread({
                    tarUserId: this.userInfos.userId,
                })
                this.unreads = data
            } catch (err) {
                this.unreads = 0
            }
        },

        clearUnread() {
            this.unreads = 0
        }
    }
}
</script>

<style lang="less" scoped>
.left-box {
    width: 240px;
    margin-right: 20px;

    .left-box__top {
        background: #fff;
        padding: 40px 20px;
        width: 240px;
        position: relative;

        .settle-tag {
            border-radius: 0 10px 10px 0;
            padding: 4px 8px;
            position: absolute;
            left: 0;
            top: 10px;
            font-size: 12px;
        }

        .head-image {
            width: 50px;
            height: 50px;
            border-radius: 40px;
            margin-bottom: 20px;
        }

    }

    .left-box__bottom {
        background: #fff;
        margin-top: 20px;
        width: 240px;
        color: #888;

        .nav-item {
            position: relative;
            color: #888888;
            font-size: 16px;
            text-align: left;
            padding: 12px 0 12px 20px;
            width: 240px;
            margin-top: 20px;
        }

        .nav-item-select {
            color: #007EF2;
            background: #F8FDFF;
        }
    }
}
</style>