<template>
    <div class="content">
        <left-bar></left-bar>
        <div class="right-box">
            <div>
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/personalCenter/personalCenter' }">个人中心</el-breadcrumb-item>
                    <el-breadcrumb-item>个人认证</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <!-- 基本信息 -->
            <div class="right-box__top">
                <div class="right-box-bar mt30">
                    <span>个人认证</span>
                    <el-button plain size="small" style="width:68px;padding:7px 0" @click="toNavBack">返回</el-button>
                </div>
                <div style="padding:40px 20px;">
                    <div style="text-align:left;">
                        <el-form :model="formData" label-position="right" label-width="100px" :rules="rules" ref="form">
                            <el-form-item label="身份证姓名" prop="realName">
                                <el-input v-model="formData.realName" placeholder="请输入身份证姓名" style="width:200px;"
                                    class="ml20"></el-input>
                            </el-form-item>
                            <el-form-item label="身份证号码" prop="idCard">
                                <el-input v-model="formData.idCard" placeholder="请输入身份证号码" style="width:400px"
                                    :maxlength="18" class="ml20"></el-input>
                            </el-form-item>
                            <el-form-item label="身份证地址" prop="realAddress">
                                <el-input v-model="formData.realAddress" placeholder="请输入身份证地址" style="width:400px"
                                    class="ml20"></el-input>
                            </el-form-item>
                            <el-form-item label="联系电话" prop="contactNumber">
                                <el-input v-model="formData.contactNumber" placeholder="请输入昵称" style="width:200px"
                                    class="ml20"></el-input>
                            </el-form-item>
                            <el-form-item label="验证码" prop="code">
                                <el-input v-model="formData.smsCode" placeholder="请输入昵称" style="width:200px"
                                    class="ml20"></el-input>

                                <el-button type="primary" @click="toSendCode" :disabled="!canSendCode" size="small"
                                    class="btn" style="margin-left:10px;width:100px"
                                    :style="{ background: canSendCode ? '' : '#E2E5EC', 'border-color': canSendCode ? '' : '#E2E5EC' }">
                                    {{ countDown === 60 ? "发送验证码" : countDown + 's重新发送' }}
                                </el-button>

                            </el-form-item>
                            <el-form-item label="身份证照片">
                                <div class="ml20 flex-row-start">
                                    <div>
                                        <div v-if="formData.realIdFrontUrl" class="upload-box">
                                            <el-image class="idcard-image" :preview-src-list="[formData.realIdFrontUrl]"
                                                :src="formData.realIdFrontUrl" fit="cover" ref="uploadImageView"></el-image>
                                            <div class="a-image-mask">
                                                <template>
                                                    <span class="a-image-mask-icon" @click="handleView(0)"><i
                                                            class="el-icon-view"></i></span>
                                                    <span class="a-image-mask-icon" @click="handleDelete('front')"><i
                                                            class="el-icon-delete"></i></span>
                                                </template>
                                            </div>
                                        </div>
                                        <el-upload accept=".png,.jpg,.jpeg" :limit="1" action="#"
                                            :http-request="toUploadFile" v-else :headers="headers" :show-file-list="false"
                                            :before-upload="onBeforeUpload">
                                            <div class="upload-box">
                                                <div class="icon">
                                                    <i class="el-icon-plus"></i>
                                                </div>
                                                <span class="upload-tips"> 身份证正面</span>
                                            </div>
                                        </el-upload>
                                    </div>

                                    <div style="margin-left:10px">
                                        <div v-if="formData.realIdBackUrl" class="upload-box">
                                            <el-image class="idcard-image" :preview-src-list="[formData.realIdBackUrl]"
                                                :src="formData.realIdBackUrl" fit="cover"></el-image>
                                            <div class="a-image-mask">
                                                <template>
                                                    <span class="a-image-mask-icon" @click="handleView(1)"><i
                                                            class="el-icon-view"></i></span>
                                                    <span class="a-image-mask-icon" @click="handleDelete('back')"><i
                                                            class="el-icon-delete"></i></span>
                                                </template>
                                            </div>
                                        </div>
                                        <el-upload accept=".png,.jpg,.jpeg" :limit="1" action="#"
                                            :before-upload="onBeforeUpload" :http-request="toUploadFileBack"
                                            :headers="headers" :show-file-list="false" v-else>
                                            <div class="upload-box">
                                                <div class="icon">
                                                    <i class="el-icon-plus"></i>
                                                </div>
                                                <span class="upload-tips">
                                                    身份证反面
                                                </span>
                                            </div>
                                        </el-upload>
                                    </div>

                                </div>
                            </el-form-item>

                            <el-form-item>
                                <el-button type="primary" class="ml20 btn" v-loading="submitLoading" style="width:160px;"
                                    @click="onSubmit">
                                    提交
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapState } from "vuex"
import leftBar from '@/views/personalCenter/components/left-bar/left-bar'
import { isIdCard, isPhone } from "@/utils/validate"
import Setting from "@src/setting.js"
import uploadToOss from "@/utils/uploadToOss"
import Utils from "@/utils/shift"
import {
    NEWsendVerifyCode,
    checkVerifyCode,
    saveRealPersonInfo,
    getIdCardByBaidu
} from "@api/common";
export default {
    components: {
        leftBar,
    },
    data() {
        var validateIdCard = function (rule, value, callback) {
            if (!isIdCard(value)) {
                callback(new Error('请输入正确的身份证号码'));
            }
            callback();
        }
        var validatePhone = function (rule, value, callback) {
            if (!isPhone(value)) {
                callback(new Error('请输入正确的手机号码'));
            }
            callback();
        }
        return {
            formData: {
                realIdBackUrl: '',
                realIdFrontUrl: '',
                realName: '',
                idCard: '',
                contactNumber: '',
                realAddress: '',
                smsCode: "",
                realBirthday: "",
                realGender: "",
            },
            rules: {
                realName: [{ required: true, message: '请输入身份证姓名', trigger: 'blur' }],
                idCard: [{ required: true, message: '请输入身份证号码', trigger: 'blur' }, { validator: validateIdCard, trigger: 'blur' }],
                realAddress: [{ required: true, message: '请输入身份证地址', trigger: 'blur' }],
                contactNumber: [{ required: true, message: '请输入联系电话', trigger: 'blur' }, { validator: validatePhone, trigger: 'blur' }],
                smsCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
            },
            submitLoading: false,
            isSendCode: false,//是否发送过验证码
            canSendCode: true,//是否可以发送验证码
            countDown: 60,
        }
    },
    computed: {
        ...mapState("account", ["userInfos", "token"]),
        headers() {
            var token = this.token;
            var headers = Setting.apiHeaders
            headers["X-Litemall-Admin-Token"] = token
            return headers
        },
    },

    created() {
        document.title = "邦建通-个人中心";
    },
    methods: {
        async onSubmit() {
            try {
                this.$refs.form.validate(async vali => {
                    if (!vali) return
                    if (!this.isSendCode) {
                        this.$message.warning("请先获取验证码")
                        return
                    }
                    this.submitLoading = true
                    let flag = await this.checkVerifyCode()
                    if (!flag) {
                        this.submitLoading = false
                        return
                    }
                    let params = {
                        settleType: 'bjt',
                        ...this.formData
                    }
                    await saveRealPersonInfo(params)
                    this.$message.success("提交成功")
                    // 刷新store中的userInfos
                    this.$store.dispatch("account/getNewUserInfo")
                })
            } catch (err) {
                console.log("提交失败", err)
                // this.$message.error(err?.msg || "提交失败")
            } finally {
                this.submitLoading = false
            }
        },

        // 发送验证码
        async toSendCode() {
            try {
                let phone = this.formData.contactNumber
                if (!phone) {
                    this.$message.warning("请输入联系电话")
                    return
                }
                if (!isPhone(phone)) {
                    this.$message.warning("请输入正确的联系电话")
                    return
                }
                if (!this.canSendCode) return
                if (this.timer) {
                    this.countDown = 60
                    clearInterval(this.timer)
                }
                await NEWsendVerifyCode({
                    phone,
                    smsEnum: "PHONE_COMMON",
                })
                this.timer = setInterval(() => {
                    if (!this.isSendCode) this.isSendCode = true
                    this.countDown--
                    if (this.countDown <= 0) {
                        this.countDown = 60
                        this.canSendCode = true
                        clearInterval(this.timer)
                    } else {
                        this.canSendCode = false
                    }
                }, 1000);
                that.$message.success("发送短信成功");
            } catch (err) {

            }
        },

        async checkVerifyCode() {
            return new Promise(async (resolve) => {
                try {
                    await checkVerifyCode({
                        verifyCode: this.formData.smsCode,
                        phone: this.formData.contactNumber,
                        verifyType: 9,
                    })
                    resolve(true)
                } catch (err) {
                    // this.$message("校验验证码失败")
                    resolve(false)
                }
            })
        },

        toNavBack() {
            this.$router.replace("/personalCenter/personalCenter")
        },

        // 查看大图
        handleView() {
            this.$refs.uploadImageView.clickHandler()
        },

        handleDelete(type) {
            if (type === "front") {
                this.formData.realIdFrontUrl = ""
            } else {
                this.formData.realIdBackUrl = ""
            }
        },

        async toUploadFile(data) {
            try {
                let res = await this.getFileUrl(data.file)
                this.checkIdCard(1, res)
            } catch (err) {
                // this.$message.error("上传文件错误")
            }
        },

        // 上传背面
        async toUploadFileBack(data) {
            try {
                let res = await this.getFileUrl(data.file)
                this.checkIdCard(2, res)
            } catch (err) {
                // this.$message.error("上传文件错误")
            }
        },

        async checkIdCard(side, url) {
            try {
                let params = {
                    identityCardOssUrl: url,
                    side,
                }
                let { data } = await getIdCardByBaidu(params)
                if (side === 1) {
                    this.formData.realIdFrontUrl = url
                    this.formData.realName = data?.name || ""
                    this.formData.idCard = data?.idnumber || ""
                    this.formData.realGender = data?.gender || ""
                    this.formData.realBirthday = data?.birthDate || ""
                    this.formData.realAddress = data?.address || ""
                } else {
                    this.formData.realIdBackUrl = url
                }
            } catch (err) {
                if (side === 1) {
                    this.formData.realIdFrontUrl = ""
                    this.formData.realName = ""
                    this.formData.idCard = ""
                    this.formData.realGender = ""
                    this.formData.realBirthday = ""
                    this.formData.realAddress = ""
                } else {
                    this.formData.realIdBackUrl = ""
                }
                // this.$message.error(err?.msg || "身份证识别异常")
            }
        },

        // 文件上传之前的回调函数
        async onBeforeUpload(file) {
            var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);
            if (!/^png|jpg|jpeg$/.test(fileExtension)) {
                this.$message.warning(`仅支持格式为png、jpg、jpeg的格式上传!`)
                return Promise.reject()
            }
            const fileSize = 2 * 1024 * 1024;
            if (fileSize < file.size) {
                this.$message.warning(`文件大小不能超过2MB`)
                return Promise.reject()
            }

            return true
        },
        getFileUrl(file) {
            return new Promise(async (resolve, reject) => {
                try {
                    let res = await uploadToOss.uploadFile(file, "image")
                    resolve(res)
                } catch (err) {
                    reject(err)
                }
            })
        },

    }
}
</script>

<style scoped lang="less">
.flex-row-start {
    display: flex;
    align-items: flex-start;
}

.mt30 {
    margin-top: 30px;
}

.ml20 {
    margin-left: 20px;
}

.content {
    background: #F8F9FB;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    font-family: Source Han Sans CN-Regular;
    color: #212121;
    font-size: 16px;

    .btn {
        background: #2180EF;
        padding: 12px 0;
    }
}

.right-box {
    .right-box__top {
        width: 1260px;
        background: #fff;
        margin-bottom: 160px;

        .head-image {
            width: 70px;
            height: 70px;
            border-radius: 70px;
            margin-bottom: 10px;
        }
    }

    .right-box__bottom {
        margin-top: 20px;
        background: #fff;
    }

    .right-box-bar {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #EDF4FF;

        span {
            font-weight: 600;
        }
    }
}

.idcard-image {
    width: 200px;
    height: 126px;
    border-radius: 4px;
}

.upload-box {
    width: 200px;
    height: 126px;
    border: 1px solid #C6CBD5;
    border-radius: 4px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon {
        font-size: 30px;
        font-weight: bold;
        color: #888888;
    }

    .upload-tips {
        font-size: 16px;
        font-weight: bold;
        color: #888;
    }
}

.upload-box:hover .a-image-mask {
    display: block;
}

.a-image-mask {
    display: none;
    width: 200px;
    height: 126px;
    line-height: 126px;
    position: absolute;
    z-index: 2;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.a-image-mask>.a-image-mask-icon {
    margin: 0 5px;
    cursor: pointer;
    font-size: 16px;
}
</style>